import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Modal, Row} from 'react-bootstrap';
import GroupManager from './Groupmanager';
import {TeamMemberModel} from "../../../member/TeamMemberModel";
import {ExerciseModel} from "./ExcerciseModels";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPeopleGroup, faTrash} from "@fortawesome/free-solid-svg-icons";
import {getGroupsForExercise, submitTrainingGroupsForExercise} from "../TrainingHelper";
import {useParams} from "react-router-dom";
import Group from "./Group";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import ExerciseImage from "./ExerciseImage";
import QuillDisplay from '../../../../helper/QuillDisplay';

interface ExerciseItemProps {
    exercise: ExerciseModel;
    players: TeamMemberModel[];
    onUpdateGroups: (exerciseId: string, groups: TeamMemberModel[][]) => void;
    onDeleteExercise: (exerciseId: string) => void;
    onEditExercise: () => void;
}

const ExerciseItem: React.FC<ExerciseItemProps> = ({exercise, players, onUpdateGroups, onDeleteExercise, onEditExercise}) => {
    const {id} = useParams<{ id?: string }>();
    const [showModal, setShowModal] = useState(false);
    const [groups, setGroups] = useState<TeamMemberModel[][]>([]);
    const [availablePlayers, setAvailablePlayers] = useState<TeamMemberModel[]>(players);
    const [numGroups, setNumGroups] = useState<number>(0);

    useEffect(() => {
        const loadGroups = async () => {
            if (exercise.id) {
                try {
                    const fetchedGroups = await getGroupsForExercise(Number(exercise.id));
                    const groupedPlayers = fetchedGroups.map((groupAssignment) => groupAssignment.players);
                    const assignedPlayerIds = groupedPlayers.flat().map((p) => p.id);
                    const unassignedPlayers = players.filter((p) => !assignedPlayerIds.includes(p.id));

                    setGroups(groupedPlayers);
                    setAvailablePlayers(unassignedPlayers);
                } catch (error) {
                    console.error("Fehler beim Abrufen der Gruppen:", error);
                }
            }
        };

        loadGroups();

    }, [exercise, players]);

    const handleDeleteExercise = () => {
        if (window.confirm(`Möchten Sie die Übung "${exercise.name}" wirklich löschen?`)) {
            onDeleteExercise(exercise.id);
        }
    };

    const handleRemovePlayer = (playerId: string, groupIndex?: number) => {
        if (groupIndex !== undefined) {
            setGroups((prevGroups) =>
                prevGroups.map((group, index) =>
                    index === groupIndex ? group.filter((player) => player.id !== playerId) : group
                )
            );
        }
        const removedPlayer = groups[groupIndex!].find((player) => player.id === playerId);
        if (removedPlayer) setAvailablePlayers((prev) => [...prev, removedPlayer]);
    };

    const handleDropPlayer = (player: TeamMemberModel, targetGroupIndex: number) => {
        setGroups((prev) =>
            prev.map((group, i) => {
                if (i === targetGroupIndex) {
                    // Überprüfe, ob der Spieler schon in der Gruppe ist
                    if (!group.some((p) => p.id === player.id)) {
                        return [...group, player]; // Spieler nur hinzufügen, wenn er nicht schon da ist
                    }
                }
                if (group.some((p) => p.id === player.id)) {
                    return group.filter((p) => p.id !== player.id); // Spieler aus der alten Gruppe entfernen
                }
                return group;
            })
        );
        setAvailablePlayers((prev) => prev.filter((p) => p.id !== player.id)); // Entferne Spieler aus der verfügbaren Liste
    };

    const handleRemoveGroup = (groupIndex: number) => {
        setGroups((prevGroups) => {
            const removedGroup = prevGroups[groupIndex];
            setAvailablePlayers((prev) => [...prev, ...removedGroup]);
            return prevGroups.filter((_, index) => index !== groupIndex);
        });
    };

    const shuffleArray = <T, >(array: T[]): T[] => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const createOneGroup = () => {
        setGroups((prev) => [...prev, []]);
    };

    const createGroups = () => {
        const newGroups: TeamMemberModel[][] = Array.from({length: numGroups}, () => []);
        setGroups(newGroups);
        setAvailablePlayers(players);
    };

    const createAndDistributeGroups = () => {
        if (numGroups <= 0) return;

        // Spieler aus bestehenden Gruppen zurück in die verfügbare Liste legen
        const allPlayers = groups.flat();
        const updatedAvailablePlayers = [...availablePlayers, ...allPlayers];

        const shuffledPlayers = shuffleArray([...updatedAvailablePlayers]);
        const newGroups: TeamMemberModel[][] = Array.from({length: numGroups}, () => []);

        shuffledPlayers.forEach((player, index) => {
            const groupIndex = index % numGroups;
            newGroups[groupIndex].push(player);
        });

        setGroups(newGroups);
        setAvailablePlayers([]); // Alle Spieler wurden zugewiesen
    };

    const distributePlayers = () => {
        const allPlayers = groups.flat();
        const updatedAvailablePlayers = [...availablePlayers, ...allPlayers];

        const shuffledPlayers = shuffleArray([...updatedAvailablePlayers]);
        const updatedGroups: TeamMemberModel[][] = Array.from({length: groups.length}, () => []); // Typ explizit angegeben

        shuffledPlayers.forEach((player, index) => {
            const groupIndex = index % updatedGroups.length;
            updatedGroups[groupIndex].push(player);
        });

        setGroups(updatedGroups);
        setAvailablePlayers([]);
    };

    const handleSave = async () => {
        if (!id || !exercise.id) {
            console.error("Fehler: trainingId ist nicht gesetzt.");
            return;
        }

        try {
            const groupAssignments = groups.map((group, index) => ({
                id: `${exercise.id + id + index}`,
                exerciseId: exercise.id,
                trainingId: id,
                players: group,
            }));

            await submitTrainingGroupsForExercise(groupAssignments);

            onUpdateGroups(exercise.id, groups);
        } catch (error) {
            console.error("Fehler beim Speichern der Gruppen:", error);
        }
    };

    const handleCloseModal = () => {
        handleSave()
        setShowModal(false);
    };

    const handleShowModal = () => setShowModal(true);

    return (
        <Card className={"mb-4 wast"}>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>{exercise.name}</Card.Title>
                <div>
                    {/* TODO in Buttons auslagern*/}
                    <button onClick={onEditExercise} className="btn btn-success me-2">
                        <FontAwesomeIcon icon={faEdit} className=""/>
                    </button>
                    <button onClick={handleShowModal} className="btn btn-success me-2">
                        <FontAwesomeIcon icon={faPeopleGroup} className=""/>
                    </button>
                    <button onClick={handleDeleteExercise} className="btn btn-danger">
                        <FontAwesomeIcon icon={faTrash} className=""/>
                    </button>
                </div>
            </Card.Header>
            <Card.Body>
                <Row className={"container-fluid"}>
                    <Col xs={12} lg={6} className={"d-flex justify-content-evenly"}>
                        <ExerciseImage exerciseId={exercise.id!} base64ImageLocal={exercise.base64ImageLocal!} />
                    </Col>
                    <Col xs={12} lg={6}>
                        {exercise.description && (
                            <Card.Text>
                                <QuillDisplay description={exercise.description} />
                            </Card.Text>
                        )}
                    </Col>
                </Row>
                <DndProvider backend={HTML5Backend} >
                    <Row className={"container-fluid d-flex justify-content-evenly mt-2"}>
                        {exercise.id && (
                            groups.map((group, groupIndex) => (
                                <Col xs={12} md={6}
                                    key={groupIndex + "edit"}>
                                    <Group
                                        groupMember={group}
                                        groupIndex={groupIndex}
                                        onRemovePlayer={handleRemovePlayer}
                                        onRemoveGroup={handleRemoveGroup}
                                        onDropPlayer={handleDropPlayer}
                                        showOnly={true}
                                    />
                                </Col>
                            ))
                        )}
                    </Row>
                </DndProvider>
            </Card.Body>
            {/* Modal für GroupManager */}
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Gruppenverwaltung</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GroupManager
                        players={players}
                        availablePlayers={availablePlayers}
                        exerciseId={exercise.id}
                        onUpdateGroups={onUpdateGroups}
                        onRemovePlayer={handleRemovePlayer}
                        onRemoveGroup={handleRemoveGroup}
                        onDropPlayer={handleDropPlayer}
                        groups={groups}
                        setGroups={setGroups}
                        setAvailablePlayers={setAvailablePlayers}
                        createOneGroup={createOneGroup}
                        createGroups={createGroups}
                        createAndDistributeGroups={createAndDistributeGroups}
                        distributePlayers={distributePlayers}
                        numGroups={numGroups}
                        setNumGroups={setNumGroups}
                        handleSave={handleSave}
                    />
                </Modal.Body>
                {/*TODO Buttons in TextButton umwandeln*/}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );
};

export default ExerciseItem;