import React from 'react';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import '../../static/css/appointment/training/createTrainingButton.css';
import {useRoutes} from '../../routes/RoutesContext';

interface IconButtonProps {
    buttonVariant: string;
    buttonClasses: string;
    icon: IconDefinition;
    iconClasses: string;
    text: string;
    textClasses: string;
    onClick?: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({
                                                       buttonVariant,
                                                       buttonClasses,
                                                       icon,
                                                       iconClasses,
                                                       text,
                                                       textClasses,
                                                       onClick,
                                                   }) => {
    return (
        <Button
            variant={buttonVariant}
            className={buttonClasses}
            onClick={onClick}
        >
            <FontAwesomeIcon className={iconClasses} icon={icon}/>
            <span className={textClasses}>{text}</span>
        </Button>
    );
};

export const TrainingOverviewActionButton: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const { routes, loading } = useRoutes();
    return (
        <IconButton buttonVariant={"outline-success"}
                    buttonClasses={"create-training-button me-2"}
                    icon={faPlus}
                    iconClasses={""}
                    text={t("training_create")}
                    textClasses={""}
                    onClick={() => navigate(routes.createTraining?.path)}
        />)
};

export const MatchOverviewActionButton: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const { routes, loading } = useRoutes();
    return (<IconButton buttonVariant={"outline-success"}
                        buttonClasses={"create-training-button me-2"}
                        icon={faPlus}
                        iconClasses={""}
                        text={t("match_create")}
                        textClasses={""}
                        onClick={() => navigate(routes.createMatch?.path)}
    />)
};

export const TrainingOverviewStatisticActionButton: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const { routes, loading } = useRoutes();
    return (<IconButton buttonVariant={"outline-success"}
                        buttonClasses={"create-training-button me-2"}
                        icon={faPlus}
                        iconClasses={""}
                        text={t("view_statistics")}
                        textClasses={""}
                        onClick={() => navigate(routes.statistic?.path)}
    />)
};

interface TrainingLineUpActionButtonProps {
    id: number;
}

export const TrainingLineUpActionButton: React.FC<TrainingLineUpActionButtonProps> = ({id}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const { routes, loading } = useRoutes();
    return (
        <IconButton
            buttonVariant="outline-success"
            buttonClasses="me-2"
            icon={faPlus}
            iconClasses={"me-1"}
            text={t("training_plan")}
            textClasses={""}
            onClick={() => navigate(`${routes.trainingLineup?.path}/${id}`)}
        />
    );
};

interface createExerciseButtonProps {
    onClick?: () => void;
}

export const CreateExerciseActionButton: React.FC<createExerciseButtonProps> = ({onClick}) => {
    const {t} = useTranslation();
    return (
        <IconButton buttonVariant={"outline-success"}
                    buttonClasses={"create-exercise-button me-2"}
                    icon={faPlus}
                    iconClasses={"me-1"}
                    text={t("add_exercise")}
                    textClasses={""}
                    onClick={onClick}
        />)
};
