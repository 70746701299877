import React, {useRef, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import QuillEditor from "../../../../helper/QuillEditor";
import {ExerciseModel} from "./ExcerciseModels";
import ExerciseImage from "./ExerciseImage";

interface ExerciseFormProps {
    onAddOrUpdateExercise: (exercise: ExerciseModel) => void;
    initialData?: ExerciseModel | null;
}

const ExerciseForm: React.FC<ExerciseFormProps> = ({onAddOrUpdateExercise, initialData}) => {
    const {t} = useTranslation();
    const [exerciseId, setExerciseId] = useState(initialData?.id || null);
    const [name, setName] = useState(initialData?.name || '');
    const [description, setDescription] = useState(initialData?.description || '');
    const [base64Image, setBase64Image] = useState<string>(initialData?.base64Image || '');
    const [base64ImageLocal, setBase64ImageLocal] = useState<string>(initialData?.base64Image || '');
    const [imageChanged, setImageChanged] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref für das Datei-Input-Feld

    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const base64 = await toBase64(file);
            setBase64Image(base64);
            setBase64ImageLocal(base64);
            setImageChanged(true);
        }
    };

    function toBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (initialData != null) {
            initialData.base64Image = base64Image;
            initialData.base64ImageLocal = base64ImageLocal;
            initialData.name = name;
            initialData.description = description;
            onAddOrUpdateExercise(initialData!)
        } else {
            onAddOrUpdateExercise(new ExerciseModel(Date.now().toString(), "", base64Image, base64ImageLocal, name, description));

        }


        // Formular zurücksetzen
        setName('');
        setDescription('');
        setBase64Image('');
        setBase64ImageLocal('');

        // Datei-Input zurücksetzen
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit} className="p-3 bg-light rounded shadow-sm">
                <Form.Group controlId="image" className="mt-3 d-flex flex-column align-items-center">
                    <Form.Label>{t("image_upload_label")}</Form.Label>
                    <Button
                        variant="primary"
                        className="d-flex align-items-center gap-2"
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <FontAwesomeIcon icon={faImage}/> {t("image_upload_label")}
                    </Button>
                    <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                        className="d-none"
                    />

                    <div className={"mt-3"}>
                        <ExerciseImage exerciseId={imageChanged ? "" : exerciseId!}
                                       base64ImageLocal={base64ImageLocal!}/>
                    </div>
                </Form.Group>

                <Form.Group controlId="name" className="mt-3">
                    <Form.Label>{t("name")}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder={t("name_placeholder")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="description" className="mt-3">
                    <Form.Label>{t("description")}</Form.Label>
                    <QuillEditor description={description} setDescription={setDescription}/>
                </Form.Group>

                <div className="d-flex justify-content-center">
                    <Button type="submit" className="mt-3 w-100">{t("add_exercise")}</Button>
                </div>
            </Form>
        </>
    );
};

export default ExerciseForm;
