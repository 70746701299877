import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Col, Container, Modal, Row} from 'react-bootstrap';
import ExerciseForm from "../exercise/ExerciseForm";
import ExerciseItem from "../exercise/ExerciseItem";
import {
    deleteTrainingExercise,
    getAppointmentById,
    getAttendanceForAppointment,
    getTrainingExercise,
    submitTrainingExercise
} from "../TrainingHelper";
import {ExerciseModel} from "../exercise/ExcerciseModels";
import {TeamMemberModel} from "../../../member/TeamMemberModel";
import {AttendanceStatus} from "../attendance/AttendanceModel";
import {useTranslation} from "react-i18next";
import {CreateExerciseActionButton} from "../../../customButtons/IconButtons";

const TrainingLineUp = () => {
    const {t} = useTranslation();
    const {id} = useParams<{ id?: string }>();
    const [exercises, setExercises] = useState<ExerciseModel[]>([]);
    const [selectedExercise, setSelectedExercise] = useState<ExerciseModel | null>(null);
    const [attendance, setAttendance] = useState<TeamMemberModel[]>([]);
    const [showModal, setShowModal] = useState(false);

    // Lade Daten bei Initialisierung
    useEffect(() => {
        if (id) {
            loadTrainingData(id);
        }
    }, [id]);

    const loadTrainingData = async (id: string): Promise<void> => {
        try {
            // Lade Anwesenheits- und Übungsdaten
            const [trainingData, attendanceData, exerciseData] = await Promise.all([
                getAppointmentById(id),
                getAttendanceForAppointment(Number(id)),
                getTrainingExercise(Number(id))
            ]);

            if (trainingData && attendanceData) {
                const attendingPlayers = attendanceData
                    .filter(att => att.status === AttendanceStatus.YES)
                    .map(att => att.player);
                setAttendance(attendingPlayers);
            }
            setExercises(exerciseData);
        } catch (error) {
            console.error("Fehler beim Laden der Trainingsdaten:", error);
        }
    };

    const addOrUpdateExercise = async (exercise: ExerciseModel) => {
        const existingIndex = exercises.findIndex(ex => ex.id === exercise.id);
        let updatedExercises;

        if (existingIndex !== -1) {
            updatedExercises = [...exercises];
            updatedExercises[existingIndex] = {...exercises[existingIndex], ...exercise};

        } else {
            exercise.appointmentId = id;
            exercise.sortOrder = exercises.length;
            updatedExercises = [...exercises, exercise];
        }

        setExercises(updatedExercises);
        await submitTrainingExercise(updatedExercises);
        setShowModal(false);
    };

    // Gruppen aktualisieren
    const updateGroups = (exerciseId: string, groups: TeamMemberModel[][]) => {
        setExercises(prevExercises =>
            prevExercises.map(exercise =>
                exercise.id === exerciseId ? {...exercise, groups} : exercise
            )
        );
    };

    // Übung löschen
    const handleDeleteExercise = async (exerciseId: string) => {
        try {
            await deleteTrainingExercise(exerciseId);
            setExercises(exercises.filter(ex => ex.id !== exerciseId));
        } catch (error) {
            console.error("Fehler beim Löschen der Übung:", error);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedExercise(null);
    }

    return (
        <Container className="mt-4">
            <h1 className="text-center mb-4">{t("training_exercise")}</h1>
            {/* Übungsliste */}
            <div className={""}>
                <Row className="mb-4">
                    {exercises
                        .slice()
                        .sort((a, b) => Number(a.id) - Number(b.id))
                        .map(exercise => (
                            <Col key={exercise.id} xs={12}>
                                <ExerciseItem
                                    exercise={exercise}
                                    players={attendance}
                                    onUpdateGroups={updateGroups}
                                    onDeleteExercise={handleDeleteExercise}
                                    onEditExercise={() => {
                                        setSelectedExercise(exercise);
                                        setShowModal(true);
                                    }}
                                />
                            </Col>
                        ))}
                </Row>
            </div>


            <center>
                <CreateExerciseActionButton onClick={() => {
                    setShowModal(true)
                }}/>
            </center>


            {/* Übungsformular */}
            <Modal show={showModal} onHide={() => closeModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t("add_exercise")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ExerciseForm onAddOrUpdateExercise={addOrUpdateExercise} initialData={selectedExercise}/>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default TrainingLineUp;