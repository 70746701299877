import React from "react";
import DOMPurify from "dompurify";

interface QuillDisplayProps {
    description: string;
}

const QuillDisplay: React.FC<QuillDisplayProps> = ({ description }) => {
    const cleanDescription = DOMPurify.sanitize(description);

    return (
        <div>
            <div
                dangerouslySetInnerHTML={{ __html: cleanDescription }}
            />
        </div>
    );
};

export default QuillDisplay;
