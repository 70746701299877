import React, {useEffect, useState} from "react";
import {getTrainingExerciseImage} from "../TrainingHelper";
import overheadKick from "../../../../static/images/fallrueckzieher.svg";
import {Button, Modal} from "react-bootstrap";

const ExerciseImage: React.FC<{ exerciseId: string; base64ImageLocal: string }> = ({
                                                                                       exerciseId,
                                                                                       base64ImageLocal,
                                                                                   }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);
    const [showModal, setShowModal] = useState(false); // Einheitlicher Modal-State
    const [modalImage, setModalImage] = useState<string | null>(null); // Modal soll einen string halten

    useEffect(() => {
        setImagePath(exerciseId);
    }, [exerciseId, base64ImageLocal]);

    async function setImagePath(exerciseId: string) {

        if (!exerciseId || isNaN(Number(exerciseId))) {
            setIsRequestCompleted(true);
            return;
        }

        try {
            const image = await getTrainingExerciseImage(exerciseId);
            setImageSrc(image);
        } catch (error) {
            console.error("Error fetching the image:", error);
        } finally {
            setIsRequestCompleted(true);
        }
    }

    const handleImageClick = (image: string) => {
        setModalImage(image); // Speichert den Bild-String fürs Modal
        setShowModal(true); // Öffnet das Modal
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage(null);
    };

    return (
        <>
            {/* Bildanzeige */}
            {imageSrc ? (
                <img
                    src={imageSrc}
                    alt="Exercise"
                    style={{width: "inherit", maxWidth: "300px", maxHeight: "300px", objectFit: "contain"}}
                    onClick={() => handleImageClick(imageSrc)} // Klick öffnet Modal
                />
            ) : base64ImageLocal ? (
                <img
                    src={base64ImageLocal}
                    alt="Exercise"
                    style={{width: "inherit", maxWidth: "300px", maxHeight: "300px", objectFit: "contain"}}
                    onClick={() => handleImageClick(base64ImageLocal)} // Klick öffnet Modal
                />
            ) : isRequestCompleted ? (
                <img src={overheadKick} alt="" style={{width: "inherit", maxWidth: "100px"}}/>
            ) : (
                <div>Is loading...</div>
            )}

            {/* Modal für Bildvergrößerung */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Body className="text-center">
                    {modalImage && <img src={modalImage} alt="Enlarged" className="img-fluid rounded"/>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Schließen</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ExerciseImage;