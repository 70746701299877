import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import '../static/css/helper/quillEditor.css';

interface QuillEditorProps {
    description: string;
    setDescription: (value: string) => void;
}

const QuillEditor: React.FC<QuillEditorProps> = ({ description, setDescription }) => {
    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            // TODO Kann vielleicht einkommentiert werden dann braucht man das Bild vorne nicht mehr
            // ["link", "image"], // Bild-Button hier
            ["clean"]
        ]
    };

    // Optionale Formate
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "list",
        "bullet",
        "link",
        "image"
    ];


    return (
        <div>
            <ReactQuill
                value={description}
                onChange={setDescription}
                modules={modules}
                formats={formats}
                theme="snow"
                className="custom-quill-editor" // Verweise auf die benutzerdefinierte CSS-Klasse
            />
        </div>
    );
};

export default QuillEditor;